import React, { Component } from 'react'; //different
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import moment from 'moment';
import md5 from 'md5';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            tblprofile: [],
            userid: null,
            linkaff: "",
            point: 0,
            useradvice: [],
            balance: 0,
            loginstate: null,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            username: "",
            amount: "",
            remark: "",
            mainacc: "",
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            name: "",
            password: "",
            left: false,
        }
    }
    logout = (e) => {

        // window.location.href = '/logout';

    }
    async componentDidMount() {
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({
                    linelink: datas[1].value,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        var userAgent = navigator.userAgent.toLowerCase()
        // if (userAgent.indexOf('safari') != -1) {
        //     if (userAgent.indexOf('chrome') > -1) {
        //     } else {
        //         await Swal.fire({
        //             title: 'Warning!',
        //             text: 'แนะนำให้ใช้งานบน Google Chorme เพื่อประสิทธิภาพที่ดีกว่า',
        //             icon: 'warning',
        //             confirmButtonText: 'ตกลง'
        //         })
        //     }
        // }

        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
        }
        catch (error) {
            //////console.log();
        }

    }
    openAccout(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className += " -open";
        }
    }
    async checkuseradvice() {
        instance.post("/api/v1/checkuseradvice", {
            User_id: this.state.username,
            page: 1,
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                console.log(res.data.message.useradvicelist);
                this.setState({
                    useradvice: res.data.message.useradvicelist
                });
            }
            else {
                // console.log(res.data);
                // this.CheckThisGoalError();
            }
        });
    }

    cancelredeempromotion = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            Swal.fire({
                icon: 'warning',
                title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await instance.post("/api/v1/usercancelpromotion", {
                        User_id: Username,
                        System: Systems,
                        no: index.no,
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            this.checkpro(null, 999);
                            Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
                        }
                        else {
                            Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
                        }
                    });

                }
            })
        }

    }

    openAccoutcan(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = "x-menu-account-list-sidebar";
        }
    }

    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        linkaff: linkaff,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        tblprofile: res.data.message,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    checkpro = async (e, proid) => {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                //console.log(res.data)
                // console.log(res.data.message.tblpromotionredeems)
                if (res.data.type === 0) {
                    this.setState({
                        prodetail: res.data.message.tblpromotions,
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        proderedeemlist: res.data.message.tblpromotionredeems,
                    });

                }

            }
            else if (res.data.status === 201) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }
            else if (res.data.status === 202) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }


    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }
    async recheccredit(evt) {

        evt.currentTarget.className += " fa-spin";
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //  this.rechecklogin(Username, token);
        this.setState({
            userid: decoded.message.playerid,
            username: decoded.message.playerid,
            firstname: decoded.message.firstname,
            lastname: decoded.message.lastname,
            loginstate: true,
        });
        try {
            //  console.log(5);
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                    var i, tablinks;
                    tablinks = document.getElementsByClassName("-btn-balance");
                    for (i = 0; i < tablinks.length; i++) {
                        tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
                    }
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    //   console.log(res.data);
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                //    console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }
    render() {

        const { loginstate, userid, username, point, firstname, lastname, balance, proderedeemlist, linkaff, tblprofile } = this.state
        // //////console.log(this.state);
        return (
            <>
                <div >
                    <nav className="x-header navbar navbar-expand-lg -anon">
                        <div className="container-fluid align-items-center h-100 position-relative">
                            <div id="headerBrand">
                                <button type="button" className="btn bg-transparent p-0 x-hamburger js-hamburger-toggle" data-toggle="modal" data-target="#themeSwitcherModal">
                                    <span />
                                    <span />
                                    <span />
                                </button>
                                <a className="navbar-brand" href="/">
                                    <img className="-logo" src="/build/web/igame-index-lobby-wm/img/logo.png?v=1" alt="Casino slot online logo"  />
                                </a>
                            </div>
                            <div id="headerContent">
                                <ul className="nav -menu-wrapper ">
                                   <li className="nav-item">
                                        <a href="#" className="nav-link -ranking " target="_self">
                                            <div className="-img-wrapper">
                                                <img src="/build/web/igame-index-lobby-wm/img/menu-icon-ranking.png" className="-icon" alt="Menu icon ranking" width={100} height={100} />
                                            </div>
                                            <div className="-text">จัดอันดับ</div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link -zean " target="_self">
                                            <div className="-img-wrapper">
                                                <img src="/build/web/igame-index-lobby-ae-sexy/img/menu-icon-sood.png" className="-icon" alt="Menu icon zean" width={54} height={50} />
                                            </div>
                                            <div className="-text">กลยุทธ์</div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/promotions" className="nav-link -movie " target="_blank" rel="noopener nofollow">
                                            <div className="-img-wrapper">
                                                <img src="/build/web/igame-index-lobby-ae-sexy/img/menu-icon-invitation.png" className="-icon" alt="Menu icon movie" width={84} height={51} />
                                            </div>
                                            <div className="-text">ชวนเพื่อน</div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/promotions" className="nav-link -promotion " target="_self">
                                            <div className="-img-wrapper">
                                                <img src="/build/web/igame-index-lobby-ae-sexy/img/menu-icon-promotion.png" className="-icon" alt="Menu icon promotion" width={100} height={100} />
                                            </div>
                                            <div className="-text">โปรโมชั่น</div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/promotions" className="nav-link -event " target="_self">
                                            <div className="-img-wrapper">
                                                <img src="/build/web/igame-index-lobby-wm/img/menu-icon-event.png" className="-icon" alt="Menu icon event" width={100} height={100} />
                                            </div>
                                            <div className="-text">สิทธิพิเศษ</div>
                                        </a>
                                    </li>
                                </ul>
                                <div className="navbar-nav">
                                    {(loginstate != null) ? (!loginstate) ?
                                        <div className="d-flex x-anon ">
                                            {/* <a href="#loginModal" className="btn x-btn-register-header d-lg-none" data-toggle="modal" data-target="#loginModal">
                                               
                                                <span className="-text-btn">เข้าสู่ระบบ</span>
                                            </a> */}
                                            <a href="#loginModal" className="btn -btn-header-login" data-toggle="modal" data-target="#loginModal">
                                               
                                                <span className="-text-btn">เข้าสู่ระบบ</span>
                                            </a>
                                        </div>
                                        :
                                        <div className="x-logged">
                                            <div className="-deposit-container d-none d-lg-block">
                                                <a
                                                    href="#deposit-choose-promotion"
                                                    className="text-white js-account-approve-aware btn -btn-deposit"
                                                    data-toggle="modal"
                                                    data-target="#depositChoosePromotionModal"
                                                >
                                                    <div className="f-7">ฝากเงิน</div>
                                                </a>
                                            </div>
                                            <div className="-withdraw-container d-none d-lg-block">
                                                <a
                                                    href="#withdraw"
                                                    className="text-white js-account-approve-aware btn -btn-withdraw"
                                                    data-toggle="modal"
                                                    data-target="#withdrawModal"
                                                >
                                                    <div className="f-7">ถอนเงิน</div>
                                                </a>
                                            </div>
                                            <div className="-profile-container ">
                                                <div className="d-none d-lg-block">
                                                    <div className="-btn-wrapper">
                                                        <div className="-inner-wrapper">
                                                            <a
                                                                href="#account"
                                                                data-toggle="modal"
                                                                data-target="#accountModal"
                                                                className="text-decoration-none"
                                                            >
                                                                <div className="-profile-name">{username}</div>
                                                            </a>
                                                            <div className="-balance-container">
                                                                <div className="-text-username">{username}</div>
                                                                <div className="-user-balance js-user-balance f-sm-6 f-7 ">
                                                                    <div className="-inner-box-wrapper">
                                                                        <img
                                                                            className="img-fluid -ic-coin"
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                                            alt=""
                                                                            width={26}
                                                                            height={21}
                                                                        />
                                                                        <span className="js-customer-balance" id="customer-balance">
                                                                            {" "}
                                                                            <span className="-amount"> <NumericFormat value={balance} displayType={'text'} thousandSeparator={true} /> </span>
                                                                        </span>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="-btn-balance"
                                                                        id="btn-customer-balance-reload"
                                                                        onClick={(e) => {
                                                                            this.recheccredit(e)
                                                                        }}
                                                                        data-target="#customer-balance"
                                                                    >
                                                                        <i className="fas fa-sync-alt f-9" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href="#account" data-toggle="modal" data-target="#accountModal">
                                                            <div className="x-profile-image">
                                                                <img
                                                                    className="img-fluid -profile-image"
                                                                    src={"/build/rank/" + this.state.rankpic}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="d-lg-none">
                                                    <div className="js-ez-logged-sidebar -btn-mobile-wrapper">
                                                        <div className="-inner-wrapper">
                                                            <a href={() => false}
                                                                className="text-decoration-none">
                                                                <div className="-profile-name">{username}</div>
                                                            </a>
                                                            <div className="-balance-container">
                                                                <div className="-text-username">{username}</div>
                                                                <div className="-user-balance js-user-balance f-sm-6 f-7 ">
                                                                    <div className="-inner-box-wrapper">


                                                                        <img
                                                                            className="img-fluid -ic-coin"
                                                                            src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                                            alt=""
                                                                            width={26}
                                                                            height={21}
                                                                        />
                                                                        <span className="js-customer-balance" id="customer-balance">
                                                                            {" "}
                                                                            <span className="-amount">{<NumericFormat value={balance} displayType={'text'} thousandSeparator={true} />}</span>
                                                                        </span>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="-btn-balance"
                                                                        id="btn-customer-balance-reload"
                                                                        onClick={(e) => {
                                                                            this.recheccredit(e)
                                                                        }}
                                                                        data-target="#customer-balance"
                                                                    >
                                                                        <i className="fas fa-sync-alt f-9" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href={() => false}
                                                            onClick={this.openAccout} >
                                                            <div className="x-profile-image">
                                                                <img
                                                                    className="img-fluid -profile-image"
                                                                    src={"/build/rank/" + this.state.rankpic}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="x-menu-account-list-sidebar">
                                                        <div className="x-modal-account-menu-mobile">
                                                            <div className="-modal-profile-mobile d-xl-none d-block">
                                                                <div className="text-right">
                                                                    <a href="#account" onClick={this.openAccoutcan}>
                                                                        <i className="fas fa-times f-5 js-close-account-sidebar" />
                                                                    </a>
                                                                </div>
                                                                <div className="x-profile-image">
                                                                    <img
                                                                        className="img-fluid -profile-image"
                                                                        src={"/build/rank/" + this.state.rankpic}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="-balance-container">
                                                                    <div className="-text-username">{username}</div>
                                                                    <div className="-user-balance js-user-balance f-sm-6 f-7 ">
                                                                        <div className="-inner-box-wrapper">
                                                                            <img
                                                                                className="img-fluid -ic-coin"
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                                                alt=""
                                                                                width={26}
                                                                                height={21}
                                                                            />
                                                                            <span className="js-customer-balance" id="customer-balance">
                                                                                {" "}
                                                                                <span className="-amount">{balance}</span>
                                                                            </span>
                                                                        </div>
                                                                        <button
                                                                            type="button"
                                                                            className="-btn-balance"
                                                                            id="btn-customer-balance-reload"
                                                                            onClick={(e) => {
                                                                                this.recheccredit(e)
                                                                            }}
                                                                            data-target="#customer-balance"
                                                                        >
                                                                            <i className="fas fa-sync-alt f-9" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="-lists-outer-wrapper">
                                                                <ul className="navbar-nav">
                                                                    <li className="nav-item -account-profile">
                                                                        <button
                                                                            type="button"
                                                                            className="nav-link js-close-account-sidebar "
                                                                            data-toggle="modal"
                                                                            data-target="#accountModalMobile"
                                                                        >
                                                                            <img
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-menu-account-user.png"
                                                                                className="img-fluid -icon-image"
                                                                                alt="ic-menu-account-user"
                                                                            />
                                                                            <span className="-text-menu">ข้อมูลบัญชี</span>
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-item -account-provider">
                                                                        <button
                                                                            onClick={(e) => {
                                                                                this.checkuseradvice()
                                                                            }}
                                                                            type="button"
                                                                            className="nav-link js-close-account-sidebar "
                                                                            data-toggle="modal"
                                                                            data-target="#providerUserModalMobile"
                                                                        >
                                                                            <img
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-menu-account-provider.png"
                                                                                className="img-fluid -icon-image"
                                                                                alt="ic-menu-account-provider"
                                                                            />
                                                                            <span className="-text-menu">ชวนเพื่อน</span>
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-item -menu-item -account-bill-history">
                                                                        <button
                                                                            type="button"
                                                                            className="nav-link js-close-account-sidebar "
                                                                            data-toggle="modal"
                                                                            data-target="#historyModal"
                                                                        >
                                                                            <img
                                                                                alt="เมนูใช้งานของผู็ใช้บนเว็บคาสิโนออนไลน์ สล็อตออนไลน์"
                                                                                className="img-fluid -icon-image"
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-menu-account-bill-history.png?v=5"
                                                                            />
                                                                            <span className="-text-menu">ประวัติทำรายการ</span>
                                                                        </button>
                                                                    </li>


                                                                    <li className="nav-item -coupon">
                                                                        <button
                                                                            type="button"
                                                                            className="nav-link js-close-account-sidebar js-account-approve-aware"
                                                                            data-toggle="modal"
                                                                            data-target="#couponModalMobile"
                                                                        >
                                                                            <img
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-menu-account-coupon.png"
                                                                                className="img-fluid -icon-image"
                                                                                alt="ic-menu-account-coupon"
                                                                            />
                                                                            <span className="-text-menu">ใช้คูปอง</span>
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-item -join-promotion">
                                                                        <button
                                                                            type="button"
                                                                            onClick={(e) => {
                                                                                this.checkpro(e, 999)
                                                                            }}
                                                                            className="nav-link js-close-account-sidebar "
                                                                            data-toggle="modal"
                                                                            data-target="#joinPromotionModalMobile"
                                                                        >
                                                                            <img
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-menu-account-promotion.png"
                                                                                className="img-fluid -icon-image"
                                                                                alt="ic-menu-account-promotion"
                                                                            />
                                                                            <span className="-text-menu">โปรโมชั่นที่เข้าร่วม</span>
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-item -promotion-return-by-user">
                                                                        <button
                                                                            type="button"
                                                                            className="nav-link js-close-account-sidebar "
                                                                            data-toggle="modal"
                                                                            data-target="#promotionReturnByUserModalMobile"
                                                                        >
                                                                            <img
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-menu-account-bonus.png"
                                                                                className="img-fluid -icon-image"
                                                                                alt="ic-menu-account-bonus"
                                                                            />
                                                                            <span className="-text-menu">รับคืนยอดเสีย</span>
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-item -logout">
                                                                        <a
                                                                            href="/logout"
                                                                            className="nav-link js-require-confirm"
                                                                            data-title="ต้องการออกจากระบบ ?"
                                                                        >
                                                                            <img
                                                                                className="img-fluid -icon-image"
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-menu-account-logout.png"
                                                                                alt="ic-menu-account-logout"
                                                                            />
                                                                            <span className="-text-menu">ออกจากระบบ</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                                <ul className="navbar-nav -action-nav">
                                                                    <li className="nav-item">
                                                                        <a
                                                                            href={() => false}
                                                                            onClick={this.onClick}
                                                                            className="nav-link js-close-account-sidebar"
                                                                            data-toggle="modal"
                                                                            data-target="#bookmarkModal"
                                                                        >
                                                                            <img
                                                                                src="/build/web/igame-index-lobby-wm/img/ic-bookmark-square.png"
                                                                                className="-img"
                                                                                alt="คาสิโนออนไลน์ สล็อตออนไลน์ บาคาร่าออนไลน์ อันดับ 1"
                                                                                width={100}
                                                                                height={100}
                                                                            />
                                                                            <div className="-text">Bookmark</div>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="-overlay" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ""
                                    }
                                </div>
                            </div>
                        </div>

                    </nav>
                    <div
                        className="x-modal modal -v2 show"
                        id="joinPromotionModalMobile"
                        tabIndex={-1}
                        role="dialog"
                        data-container="#joinPromotionModalMobile"
                        aria-modal="true"
                    >
                        <div
                            className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile -no-fixed-button -no-padding-x"
                            role="document"
                        >
                            <div className="modal-content -modal-content">
                                <button
                                    type="button"
                                    className="close f-1 "
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="fas fa-times" />
                                </button>
                                <div className="modal-header -modal-header">
                                    <div className="x-modal-mobile-header">
                                        <div className="-header-mobile-container">
                                            <h3 className="x-title-modal mx-auto text-center d-inline-block">
                                                การเข้าร่วมโปรโมชั่น
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body -modal-body">
                                    <div className="-outer-history-wrapper">
                                        <div className="x-bill-history-container">
                                            <div
                                                className="wg-container wg-container__wg_bill_history wg--loaded"
                                                data-widget-name="wg_bill_history"
                                                data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                                                data-widget-user-options='{"page":1}'
                                            >
                                                <div className="wg-content" style={{ display: (proderedeemlist.length === 0) ? "" : "none" }}>
                                                    <div className="-no-result-wrapper mt-3">
                                                        <div className="-heading-title">
                                                            <i className="fas fa-times-circle -icon" />
                                                            <div className="-title">ไม่พบข้อมูล</div>
                                                            <div className="-sub-title">คุณยังไม่มีข้อมูลการทำรายการค่ะ</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wg-content" style={{ display: (proderedeemlist.length === 0) ? "none" : "" }}>
                                                    <table className="table table-borderless table-striped">
                                                        <tbody>
                                                            {proderedeemlist.map((listitem, i) => (
                                                                <tr key={i} id={i}>
                                                                    <td className="-description-body-wrapper">
                                                                        <div className="-title-wrapper">
                                                                            <span className="-title">{listitem.proname}</span>
                                                                            <span style={{ display: (listitem.status === 1) ? "" : "none", margin: "5px" }}>
                                                                                <a href="#0"
                                                                                    onClick={(e) => {
                                                                                        this.cancelredeempromotion(e, listitem)
                                                                                    }}
                                                                                    className="-link-change-password" ><u>ยกเลิก</u></a>
                                                                            </span>
                                                                        </div>
                                                                        <div className="-state-wrapper" style={{ display: (listitem.status === 1) ? "" : "none" }}>
                                                                            <span className="-state-text">สถานะ : </span>
                                                                            <i className="fas fa-spinner fa-spin"></i>
                                                                            <span className="-state-title">รอดำเนินการ</span>
                                                                            <span className="-state-title -short">รอดำเนินการ  </span>
                                                                        </div>
                                                                        <div className="-state-wrapper" style={{ display: (listitem.status === 4) ? "" : "none" }}>
                                                                            <span className="-state-text">สถานะ : </span>
                                                                            <img
                                                                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                                className="-ic"
                                                                                alt=""
                                                                            />
                                                                            <span className="-state-title">ยกเลิก (ยกเลิกโดยผู้เล่น)</span>
                                                                            <span className="-state-title -short">ยกเลิก (โดยผู้เล่น)</span>
                                                                        </div>
                                                                        <div className="-state-wrapper" style={{ display: (listitem.status === 3) ? "" : "none" }}>
                                                                            <span className="-state-text">สถานะ : </span>
                                                                            <img
                                                                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_fail.png"
                                                                                className="-ic"
                                                                                alt=""
                                                                            />
                                                                            <span className="-state-title">ไม่สำเร็จ (โดยระบบ)</span>
                                                                            <span className="-state-title -short">ไม่สำเร็จ (โดยระบบ)</span>
                                                                        </div>
                                                                        <div className="-state-wrapper" style={{ display: (listitem.status === 2) ? "" : "none" }}>
                                                                            <span className="-state-text">สถานะ : </span>
                                                                            <img
                                                                                src="https://asset.cloudigame.co/build/admin/img/wt_theme/popular_3/ic_success.png"
                                                                                className="-ic"
                                                                                alt=""
                                                                            />
                                                                            <span className="-state-title">สำเร็จ</span>
                                                                            <span className="-state-title -short">สำเร็จ</span>
                                                                        </div>
                                                                    </td>
                                                                    <td className="-transaction-body-wrapper">
                                                                        <div className="-amount -deposit">{listitem.credit.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} บาท</div>
                                                                        <div className="-datetime">{moment(listitem.datecreate).format('DD/MM/YY') + " - " + moment(listitem.datecreate).format('HH:mm') + " น."} </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div
                    className="x-modal modal -v2 show"
                    id="providerUserModalMobile"
                    tabIndex={-1}
                    role="dialog"
                    data-container="#providerUserModalMobile"
                    aria-modal="true"
                >
                    <div
                        className="modal-dialog -modal-size  modal-dialog-centered modal-dialog-scrollable -modal-mobile -no-fixed-button"
                        role="document"
                    >
                        <div className="modal-content -modal-content">
                            <button
                                type="button"
                                className="close f-1 "
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <i className="fas fa-times" />
                            </button>
                            <div className="modal-header -modal-header">
                                <div className="x-modal-mobile-header">
                                    <div className="-header-mobile-container">
                                        <h3 className="x-title-modal mx-auto text-center d-inline-block">
                                            ชวนเพื่อน
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body -modal-body">
                                <div id="accountProviderUser" className="x-account-provider ">
                                    <div
                                        data-animatable="fadeInModal"
                                        className="-account-provider-container animated fadeInModal"
                                    >
                                        <div className="-group-trace-wrapper">
                                            <div className="x-profile-group-trace-condition ">
                                                <div className="-card-condition-wrapper">
                                                    <div className="-card-condition-heading">
                                                        <span className="-title">
                                                            รับลิงค์ชวนเพื่อน
                                                        </span>
                                                    </div>
                                                    <div className="-card-condition-description">
                                                        กดที่ลิงค์ <span className="-highlight">
                                                            <a href={() => false} style={{ cursor: "pointer" }} onClick={() => this.copyCode()} className="-copy-wrapper js-copy-to-clipboard" data-container="shareAccountProfileBankInfo" data-message="คัดลอกแล้ว!" >
                                                                {linkaff}
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <p className="-note">รายชื่อที่มี
                                                        <img src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                            className="-ic"
                                                            style={{ marginLeft: "5px" }}
                                                            width={"20px"}
                                                            alt=""
                                                        />  ครบเงื่อนใขการเติมเงินขั่นต่ำ 100 บาทแล้ว </p>
                                                    <span className="-title">
                                                        คุณได้รับเครดิตชวนเพื่อน<span className="-title" style={{ color: "#d9ad70" }}  > {tblprofile.AffiliateCash}  </span> บาท (คำนวณทุกวันอาทิตย์ เวลา 00.00น.)
                                                    </span>
                                                    {/* <span className="-title">
                            คุณได้รับเครดิตยอดเสีย<span className="-title" style={{ color: "#d9ad70" }}  > {cashoutSet != null ? cashoutSet.percent : "0"}  </span> บาท (คำนวณทุกวันอาทิตย์ เวลา 00.00น.)
                          </span> */}

                                                </div>
                                                <span className="-title">
                                                    คุณได้เปอเซนต์  <span className="-title" style={{ color: "#d9ad70" }}  >{tblprofile.AffPercent}%</span> จากยอดเสีย
                                                </span>
                                                <div className="-outer-history-wrapper" style={{ marginTop: "10px" }} >
                                                    <div className="x-bill-history-container">
                                                        <div
                                                            className="wg-container wg-container__wg_bill_history wg--loaded"
                                                            data-widget-name="wg_bill_history"
                                                            data-widget-options='{"script_path":null,"style_path":null,"image_path":null,"visibility":"away","visibility_offset":"100%","render_url":"\/_widget","render_method":"GET","attr_style":null,"attr_class":null,"scroll_position":"current","options":{},"callback":{},"mode":"clear","mask_mode":"over","mask_style":"wg-loading","limit":20,"page":1,"template":"@Base\/Widget\/billHistory.html.twig","name":"wg_bill_history"}'
                                                            data-widget-user-options='{"page":1}'
                                                        >
                                                            <div className="wg-content" style={{ display: (this.state.useradvice.length === 0) ? "" : "none" }}>
                                                                <div className="-no-result-wrapper mt-3">
                                                                    <div className="-heading-title">
                                                                        <i className="fas fa-times-circle -icon" />
                                                                        <div className="-title">ไม่พบข้อมูล</div>
                                                                        <div className="-sub-title" style={{ color: "black" }}>คุณยังไม่มีข้อมูลการชวนเพื่อนค่ะ</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="wg-content" style={{ display: (this.state.useradvice.length === 0) ? "none" : "" }}>
                                                                <table className="table table-borderless table-striped">
                                                                    <tbody>
                                                                        {this.state.useradvice.map((listitem, i) => (
                                                                            <tr key={i} id={listitem.tid}>
                                                                                <td className="-description-body-wrapper">
                                                                                    <div className="-title-wrapper">

                                                                                        {i + 1 + "."} <span className="-title" style={{ marginLeft: "0px" }}> {listitem.playerid}

                                                                                            {(listitem.checked !== 1) ? "" : <img src="/build/web/igame-index-lobby-wm/img/ic-coin.png"
                                                                                                className="-ic"
                                                                                                style={{ marginRight: "5px" }}
                                                                                                width={"20px"}
                                                                                                alt=""
                                                                                            />}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="-transaction-body-wrapper">
                                                                                    <div className="-datetime">{moment(listitem.DateCreate).format('DD/MM/YY') + " - " + moment(listitem.DateCreate).format('HH:mm') + " น."} </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div></>
        );
    }
}
export default App;
